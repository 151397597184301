import { useEffect, useState } from "react"

export const usePromo = () => {
  const [isPromo, setIsPromo] = useState(false)  

  const month = new Date().getMonth()
  
  useEffect(() => {
    const promoMonths = [2, 5, 9];
    promoMonths.includes(month) 
    ? setIsPromo(true)
    : setIsPromo(false)
    
  },[month])

  return {isPromo, month}
}