import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { navigate } from "gatsby";
import {
  description,
  container,
  descriptionContainer,
  porcelanosa,
  imageLogo,
  promo
} from "./fournisseur.module.css";
import Carroussel from "./carroussel";
import { usePromo } from "../../../hooks/usePromo";

function Fournisseur({ showroom }) {
  const {isPromo} = usePromo()
  const data = useStaticQuery(graphql`
    query {
      contentfulImagesPorcelanosa {
        porcelanosa {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
        textImagePorcelanosa
      }
      allContentfulImagePrincipal(
        filter: { mainImageText: { eq: "Logo Porcelanosa" } }
      ) {
        nodes {
          mainImage {
            gatsbyImageData(placeholder: BLURRED)
            id
          }
          description {
            description
          }
        }
      }
      contentfulImagesShowroom {
        description {
          description
        }
        images {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          id
        }
        title
      }
      contentfulImagePromo {
        imagePromo {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <div className={container} id={showroom ? "showroom" : "porcelanosa"}>
      <div className={descriptionContainer}>
        <h1 className="right">
          {showroom
            ? data.contentfulImagesShowroom.title
            : data.contentfulImagesPorcelanosa.textImagePorcelanosa}
        </h1>
        <div className={description} >
          {!showroom && (
            <div onClick={() => navigate("/porcelanosa")} className={imageLogo}>
              <GatsbyImage
                image={
                  data.allContentfulImagePrincipal.nodes[0].mainImage
                    .gatsbyImageData
                }
                alt={`Logo porcelanosa`}
                className={porcelanosa}
              />
            </div>
          )}
          <p>
            {showroom
              ? data.contentfulImagesShowroom.description.description
              : data.allContentfulImagePrincipal.nodes[0].description
                  .description}
          </p>
        </div>
      </div>
      {(isPromo && !showroom) && <GatsbyImage
                image={
                  data.contentfulImagePromo.imagePromo
                    .gatsbyImageData
                }
                alt={`Les jours porcelanosa`}
                className={promo}
              />}
      <Carroussel
        imageData={
          showroom
            ? data?.contentfulImagesShowroom?.images
            : data?.contentfulImagesPorcelanosa?.porcelanosa
        }
      />
      
    </div>
  );
}

export default Fournisseur;
